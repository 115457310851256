<template>
	<div class="shadow-lg rounded">
		<b-skeleton-wrapper :loading="isBusy">
			<template #loading>
				<b-card class="mb-1" border border-variant="primary">
					<b-row no-gutters>
						<b-col sm="6">
							<b-skeleton animation="throb" class="mt-2" width="100%" size="sm" type="input"></b-skeleton>
						</b-col>
						<b-col sm="6" class="pl-3">
							<b-skeleton animation="throb" class="mt-2" width="100%" size="sm" type="input"></b-skeleton>
						</b-col>
					</b-row>
					<b-row no-gutters>
						<b-col sm="6">
							<b-skeleton animation="throb" class="mt-2" width="100%" size="sm" type="input"></b-skeleton>
						</b-col>
						<b-col sm="6" class="pl-3">
							<b-skeleton animation="throb" class="mt-2" width="100%" size="sm" type="input"></b-skeleton>
						</b-col>
					</b-row>
					<hr />
					<b-row no-gutters>
						<b-col>
							<b-row>
								<b-col>
									<b-skeleton animation="throb" width="45%" size="sm" type="input"></b-skeleton>
								</b-col>
								<b-col sm="1">
									<b-skeleton animation="throb" width="100%" type="input"></b-skeleton>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-card>

				<b-row>
					<b-col>
						<b-card class="mb-1" border border-variant="primary" style="height:50vw; width:100%">
							<b-skeleton-img animation="throb" height="48vw" />
						</b-card>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-card class="mb-1" border border-variant="primary" style="height:100vw; width:100%">
							<b-skeleton-img animation="throb" height="98vw" />
						</b-card>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-card class="mb-1" border border-variant="primary">
							<b-skeleton-img animation="throb" />
						</b-card>
					</b-col>
					<b-col>
						<b-card class="mb-1" border border-variant="primary">
							<b-skeleton-img animation="throb" />
						</b-card>
					</b-col>
					<b-col>
						<b-card class="mb-1" border border-variant="primary">
							<b-skeleton-img animation="throb" />
						</b-card>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-card class="mb-1" border border-variant="primary">
							<b-row>
								<b-col>
									<b-skeleton-img animation="throb" />
								</b-col>
								<b-col>
									<b-skeleton-img animation="throb" />
								</b-col>
								<b-col>
									<b-skeleton-img animation="throb" />
								</b-col>
							</b-row>
						</b-card>
					</b-col>
					<b-col>
						<b-card class="mb-1" border border-variant="primary">
							<b-row>
								<b-col>
									<b-skeleton-img animation="throb" />
								</b-col>
								<b-col>
									<b-skeleton-img animation="throb" />
								</b-col>
								<b-col>
									<b-skeleton-img animation="throb" />
								</b-col>
							</b-row>
						</b-card>
					</b-col>
				</b-row>
			</template>

			<b-card v-if="device" class="mb-1" border border-variant="primary">
				<b-row no-gutters>
					<b-col sm="6">
						<b-form-group label="Company" label-for="company_name" :label-cols="4">
							<b-form-input id="company_name" type="text" placeholder="No company" v-model="device.company_name"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" class="pl-3">
						<b-form-group label="Device" label-for="device" :label-cols="2">
							<b-form-select v-model="selectedDeviceId" :options="accessDeviceOptions" @change="onSelectedDeviceId" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row no-gutters>
					<b-col sm="6">
						<b-form-group label="Alarm / Last Events" label-for="alarm" :label-cols="4">
							<b-form-input id="alarm" type="text" placeholder="No new alarms"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col sm="6" class="pl-3">
						<b-form-group label="Battery charge (days)" label-for="battery" :label-cols="2">
							<b-form-input id="battery" type="text" autocomplete="name" placeholder="Battery charge (days)" v-model="device.next_charge"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- Resulting >> {{settings}}
				<hr/>
				Device >> {{device ? device.devicesetting : "None"}}
				<hr/>
				Client >> {{datasettings}} -->
				<hr />
				<b-row no-gutters>
					<b-col>
						<chart-toolbar :show-new-data="newData" :range="range" @refresh="refreshData" @retrieve="refreshData(null)" />
					</b-col>
				</b-row>
			</b-card>

			<b-row>
				<b-col>
					<template v-if="chartDatasets">
						<multi-dataset-line-chart :selectedPoint="selectedPoint" @point-clicked="receivedPoint($event, 'temp1')" :dataCollection="chartDatasets" />
					</template>
				</b-col>
			</b-row>
			<b-row>
				<b-col v-if="settings ? settings.navigation : false">
					<template v-if="routeLocations">
						<map-route-location-chart :selectedPoint="selectedPoint" @point-clicked="receivedPoint($event, 'temp2')" :isChartDataReady="isObject(routeLocations.points)" :dataCollection="routeLocations" title="DEVICE ROUTE" />
					</template>
				</b-col>
			</b-row>
			<b-row>
				<b-col v-if="settings ? settings.location_lock : false">
					<template v-if="lockStatusLocations">
						<map-point-location-chart :selectedPoint="selectedPoint" @point-clicked="receivedPoint($event, 'lock')" :isChartDataReady="isObject(lockStatusLocations)" mapType="lock" :dataCollection="lockStatusLocations" showStatus="open" title="DEVICE DOOR LOCK STATUS: OPEN" />
					</template>
				</b-col>
				<b-col v-if="settings ? settings.location_orientation : false">
					<template v-if="orientationStatusLocations">
						<map-point-location-chart :selectedPoint="selectedPoint" @point-clicked="receivedPoint($event, 'orientation')" :isChartDataReady="isObject(orientationStatusLocations)" mapType="orientation" :dataCollection="orientationStatusLocations" showStatus="upsidedown" title="DEVICE ORIENTATION STATUS: UPSIDEDOWN" />
					</template>
				</b-col>
				<b-col v-if="settings ? settings.location_orientation : false">
					<template v-if="orientationStatusLocations">
						<map-point-location-chart :selectedPoint="selectedPoint" @point-clicked="receivedPoint($event, 'orientation')" :isChartDataReady="isObject(orientationStatusLocations)" mapType="orientation" :dataCollection="orientationStatusLocations" showStatus="sideways" title="DEVICE ORIENTATION STATUS: SIDEWAYS" />
					</template>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<template v-if="temperatureStates">
						<latest-temperature-blocks :settings="settings" :dataCollection="temperatureStates" />
					</template>
				</b-col>
				<b-col>
					<template v-if="temperatureStates">
						<highest-temperature-blocks :settings="settings" :dataCollection="temperatureStates" />
					</template>
				</b-col>
			</b-row>
		</b-skeleton-wrapper>
	</div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import ViewExtension from "../ViewExtension"

export default ViewExtension.extend({
	name: "ClientCharts",
	props: {
		deviceId: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			device: null,
			range: null,
			temperatureStates: null,
			chartDatasets: null,
			routeLocations: null,
			lockStatusLocations: null,
			orientationStatusLocations: null,

			accessDeviceOptions: null,
			selectedDeviceId: null,
			selectedPoint: null,
			settings: null,
			newData: false,
		}
	},
	computed: {},
	// watcher on state
	// this.refreshData(null)
	methods: {
		...mapActions("meds2go/device", ["deviceChartData", "deviceDetail", "getCompanyDeviceGroups"]),
		receivedPoint(pointData, source) {
			this.selectedPoint = { point: pointData, source: source }
		},
		async loadData(device_id, range = null) {
			this.loading()
			await this.deviceDetail(device_id)
				.then((results) => {
					this.device = results
				})
				.catch((errors) => {
					console.log(errors)
				})
			await this.deviceChartData({ id: device_id, start_datetime: range ? range.start : null, end_datetime: range ? range.end : null })
				.then((results) => {
					if (results.received_data) {
						this.range = results.range
						this.chartDatasets = results.datasets
						this.temperatureStates = results.states
						this.routeLocations = results.route
						this.lockStatusLocations = results.locations.lock
						this.orientationStatusLocations = results.locations.orientation
						this.settings = results.settings
						// this.toast("Device Data", "Data retrieved successfully.", "success")
					} else {
						this.range = results.range
						this.toast("Device Data", `No data available for this range.`, "warning")
					}
					this.newData = false
				})
				.catch((errors) => console.log(errors))
			this.stopLoading()
			this.companyDeviceGroups(this.device.group)
		},
		async companyDeviceGroups(devicegoupId) {
			await this.getCompanyDeviceGroups(devicegoupId).then((results) => {
				this.accessDeviceOptions = results[0].access_devices_options
			})
		},
		refreshData(range) {
			this.chartDatasets = null
			this.temperatureStates = null
			this.routeLocations = null
			this.lockStatusLocations = null
			this.orientationStatusLocations = null
			this.loadData(this.selectedDeviceId ? this.selectedDeviceId : this.deviceId, range)
		},
		onSelectedDeviceId() {
			// console.log(this.selectedDeviceId)
			this.$router.push({ path: `/dashboard/client/charts/${this.selectedDeviceId}` })
			this.loadData(this.selectedDeviceId)
		},
	},
	mounted() {
		if (this.deviceId) {
			this.selectedDeviceId = this.deviceId
			this.loadData(this.deviceId)
			// WebSocket for Updated data
			let self = this
			this.connection = new WebSocket(`ws://${window.location.host}/api/ws/device/retrieve/${this.deviceId}/`)
			this.connection.onmessage = function(event) {
				let msg = JSON.parse(event.data)
				let text = JSON.parse(msg.text)

				// self.retrieveNewData()
				if (text.device_id == self.deviceId && text.total_rows > 0) {
					self.newData = true

					self.toast("Updated Data", `Please click the New Data button to retrieve.`, "success")
				} 
			}

			this.connection.onopen = function(event) {
				console.log("Successfully connected to the echo websocket server...")
			}

			this.connection.onclose = function(event) {
				console.log("Socket Closed")
			}
		}
	},
})
</script>
